"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnsplashGetRandomImages = exports.UnsplashGetRandomImagesOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UnsplashGetRandomImagesOperationId = 'UnsplashGetRandomImages';
/**
 * Get some random images from Unsplash
 *
 * @path /unsplash/random
 * @generated
 */
exports.UnsplashGetRandomImages = (0, ClientController_1.ClientControllerFactory)({
    path: '/unsplash/random',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
